:root {
    /* Fonts */
    --font-heading: "Poppins", system-ui, Arial, sans-serif;
    --font-body: "Lato", system-ui, Arial, sans-serif;

    /* Spacing */
    --space-xs: 4px;
    --space-sm: 8px;
    --space-md: 16px;
    --space-lg: 24px;
    --space-xl: 32px;
    --space-2xl: 48px;
    --space-3xl: 64px;
    --space-4xl: 96px;

    /* Brand Colors */
    --color-primary-50: #f7e7ee;
    --color-primary-200: #ef9dc1;
    --color-primary-400: #fa4094;
    --color-primary-600: #de116e;
    --color-primary-800: #91134d;
    --color-primary: var(--color-primary-400);

    --color-secondary-50: #eceffd;
    --color-secondary-200: #a0aeee;
    --color-secondary-400: #3b4d9d;
    --color-secondary-600: #293153;
    --color-secondary-800: #1c2138;
    --color-secondary: var(--color-secondary-600);

    /* Neutral Colors */
    --color-neutral-50: #fafafa;
    --color-neutral-100: #f5f5f5;
    --color-neutral-200: #e5e5e5;
    --color-neutral-300: #d4d4d4;
    --color-neutral-400: #a1a1aa;
    --color-neutral-500: #717a7a;
    --color-neutral-600: #52525b;
    --color-neutral-700: #3f3f46;
    --color-neutral-800: #27272a;
    --color-neutral-900: #18181b;
    --color-neutral: var(--color-neutral-500);

    /* Success Colors */
    --color-success-50: #ecfdf5;
    --color-success-100: #d1fae5;
    --color-success-200: #a7f3d0;
    --color-success-300: #6ee7b7;
    --color-success-400: #34d399;
    --color-success-500: #10b981;
    --color-success-600: #059669;
    --color-success-700: #047857;
    --color-success-800: #065f46;
    --color-success-900: #064e3b;
    --color-success: var(--color-success-800);

    /* Warning Colors */
    --color-warning-50: #fffceb;
    --color-warning-100: #fef9c3;
    --color-warning-200: #feeb8a;
    --color-warning-300: #fed049;
    --color-warning-400: #fac113;
    --color-warning-500: #eab308;
    --color-warning-600: #ca9004;
    --color-warning-700: #a16207;
    --color-warning-800: #854d0e;
    --color-warning-900: #713f12;
    --color-warning: var(--color-warning-400);

    /* Destructive Colors */
    --color-destructive-50: #fef2f2;
    --color-destructive-100: #fee2e2;
    --color-destructive-200: #fecaca;
    --color-destructive-300: #fca5a5;
    --color-destructive-400: #f87171;
    --color-destructive-500: #ef4444;
    --color-destructive-600: #dc2626;
    --color-destructive-700: #b91c1c;
    --color-destructive-800: #991b1b;
    --color-destructive-900: #7f1d1d;
    --color-destructive: var(--color-destructive-500);

    /* Selection Colors */
    --color-selection-50: #eff6ff;
    --color-selection-100: #dbeafe;
    --color-selection-200: #bfdbfe;
    --color-selection-300: #93c5fd;
    --color-selection-400: #60a5fa;
    --color-selection-500: #3b82f6;
    --color-selection-600: #2563eb;
    --color-selection-700: #1d4ed8;
    --color-selection-800: #1e40af;
    --color-selection-900: #1e3a8a;
    --color-selection: var(--color-selection-500);

    /* Color Shades */
    --color-white: #ffffff;
    --color-black: #292929;

    /* Component Colors */
    --color-outline: #66afe9;
    --color-link: var(--color-selection-700);

    /* Scrollbar */
    --scrollbar-track: transparent;
    --scrollbar-thumb: var(--color-neutral-400);
    --scrollbar-thumb-radius: 6px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes flash {
    0% {
        opacity: 0;
    }
    50%,
    100% {
        opacity: 1;
    }
}

/* === ICON === */

[component="icon"] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
}

[component="icon"] > svg {
    height: 100%;
    width: 100%;
}

/* === SPINNER === */

[component="spinner"] {
    margin: 0 auto;
    box-sizing: border-box;
}

[component="spinner"]::after {
    content: " ";
    position: relative;
    box-sizing: border-box;
    display: block;
    margin: auto;
    height: var(--space-3xl);
    width: var(--space-3xl);
    border: 4px solid var(--color-primary-50);
    border-top: 4px solid var(--color-primary);
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

/* === SCROLLABLE CONTAINER === */

[scrollable] {
    scrollbar-width: auto;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
    overflow-y: overlay;
    -webkit-overflow-scrolling: touch;
}

[scrollable]::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
}

[scrollable]::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
}

[scrollable]::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: var(--scrollbar-thumb-radius);
}

/* === BUTTON === */

[component="button"] {
    --foreground: var(--color-black);
    --background: var(--color-white);
    --border: var(--color-white);
    --background-hover: var(--color-white);
    --border-hover: var(--color-white);

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--foreground);
    font-family: "Lato", system-ui, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 10.5px 16px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    transition: 300ms ease-in;
    cursor: pointer;
}

[component="button"]:hover,
[component="button"]:active {
    background-color: var(--background-hover);
    border-color: var(--border-hover);
}

[component="button"]:focus {
    outline: 1px solid var(--color-outline);
}

[component="button"][disabled],
[component="button"]:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
}

[component="button"][loading] {
    pointer-events: none;
}

[component="button"][loading] > svg > circle {
    animation: flash 0.5s infinite linear alternate;
    animation-delay: 0s;
}

[component="button"][loading] > svg > circle:nth-child(2) {
    animation-delay: 0.25s;
}

[component="button"][loading] > svg > circle:nth-child(3) {
    animation-delay: 0.5s;
}

[component="button"].sm {
    padding: 6px 37px;
}

[component="button"].md {
    padding: 10px 37px;
}

[component="button"].lg {
    padding: 16px 37px;
}

[component="button"].primary {
    --foreground: var(--color-white);
    --background: var(--color-primary);
    --border: var(--background);
    --background-hover: var(--color-primary-600);
    --border-hover: var(--background-hover);
}

[component="button"].secondary {
    --foreground: var(--color-primary);
    --background: transparent;
    --border: var(--foreground);
    --background-hover: var(--color-primary-50);
    --border-hover: var(--foreground);
}

[component="button"].tertiary {
    --foreground: var(--color-neutral-900);
    --background: var(--color-neutral-200);
    --border: var(--background);
    --background-hover: var(--color-neutral-300);
    --border-hover: var(--background-hover);
}

[component="button"].ghost {
    --foreground: var(--color-neutral-900);
    --background: transparent;
    --background-hover: var(--color-neutral-100);
    --border: var(--color-neutral-300);
    --border-hover: var(--border);
}

[component="button"].text {
    --foreground: var(--color-primary);
    --background: transparent;
    --border: var(--background);
    --background-hover: var(--color-primary-50);
    --border-hover: var(--background-hover);
}

[component="button"].destructive.primary {
    --foreground: var(--color-white);
    --background: var(--color-destructive-700);
    --background-hover: var(--color-destructive-800);
}

[component="button"].destructive.secondary {
    --foreground: var(--color-destructive-700);
    --background-hover: var(--color-destructive-50);
}

[component="button"].destructive.text {
    --foreground: var(--color-destructive-600);
    --background-hover: var(--color-destructive-50);
}

[component="button"] .icon {
    height: 20px;
    width: 20px;
}

/* === ALPINE CLOAK === */

[x-cloak] {
    display: none !important;
}
